window.addEventListener('scroll', function(event) {
    function footer() {
        var scroll = window.pageYOffset || document.documentElement.scrollTop;
        var footerNav = document.querySelector('.get--started--section');

        // Ensure footerNav exists before accessing its style property
        if (footerNav) {
            if (scroll > 50) {
                footerNav.style.display = 'block';
                footerNav.classList.add('show');
            } else {
                footerNav.style.display = 'none';
                footerNav.classList.remove('show');
            }

            // Ensure scrollTimer is a property of footerNav
            if (footerNav.scrollTimer) {
                clearTimeout(footerNav.scrollTimer);
            } ;
        } else {
            console.error('Element ".get--started--section" not found');
        }
    }

    footer();
});
